<template>
  <div>
    <div class="header">
      <a href="/spellchecker" class="home">
        Центр AI-маркетинга :: ИИ корректор
      </a>
      <!-- <a href="https://ai-marketing.space/smm_lk" class="home"
        style="display: block; margin-left: auto; margin-right: 30px;">Личный кабинет</a> -->
      <span>
        <a href="javascript:void(0)" v-b-modal.HelpForm data-toggle="modal" data-target="#HelpForm" style="border: none"
          class="home">Помощь &nbsp;&nbsp;<help-circle-icon class="fea icon-sm"></help-circle-icon></a>
      </span>
      <b-modal id="HelpForm" title="Справка по сервису" centered button-size="sm" size="lg" ok-only>
        <div class="bg-white p-1 rounded box-shadow">
          <div class="text-muted mb-2" style="font-size: small">
            <p>
              На данный момент сервис исправлеят следующие типы ошибок:
            <ul>
              <li> орфография </li>
              <li> пунктуация </li>
              <li> ё-фикация </li>
            </ul>
            </p>
            <p>
              Нашли ошибку или что-то не работает? Сообщите @ermolaevpa (тг) или paermolaev@sberbank.ru
            </p>
          </div>
        </div>
      </b-modal>
    </div>

    <div class="mainspellchecker">
      <div class="row vs-con-loading__container" id="div-with-loading" style="justify-content: center">
        <div class="col-9">
          <div class="row">
            <div class="col">
              <h1 class="title titleBold mb-2">Введите текст 👇</h1>
              <div id="textEdit" contenteditable="true" v-on:input="" class="main-div-edit color-hover"></div>
              <div class="flex-container flex-start">
                <b-button class="prompt__btn prompt__btn-submit mt-3" @click="checkPunctuation()">
                  🤓 Проверить синтаксис и пунктуацию
                </b-button>
                <b-button v-if="readyToCheckStyle" class="prompt__btn prompt__btn-submit mt-3" @click="checkStyle()">
                  Проверить стилистику
                </b-button>
                <b-button v-if="readyToCheckStyle" class="prompt__btn prompt__btn-submit mt-3" @click="checkList()">
                  Проверить списки
                </b-button>
                <b-button v-if="autoreplace" class="prompt__btn prompt__btn-submit mt-3" @click="autoReplace()">
                  🤝 Автозамена
                </b-button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2">
          <div class="row">
            <b-button class="prompt__btn prompt__btn-submit mt-5" @click="downloadInnerHtml()">💾 Сохранить в
              файл</b-button>
          </div>
          <div class="row">
            <b-button class="prompt__btn prompt__btn-submit mt-1" @click="copyInnerHtml()">🗐 Скопировать</b-button>
          </div>
          <div class="row">
            <b-button class="prompt__btn prompt__btn-submit mt-1" @click="clearInnerHtml()">🧹 Очистить</b-button>
          </div>
        </div>
      </div>

      <div class="row vs-con-loading__container" id="div-with-loading" style="justify-content: center">
        <div class="col-11">
          <div class="row">
            <div class="col">
              <br>
              <hr>
              <br>
              <div class="row" style="justify-content: left">
                <b-button class="prompt__btn prompt__btn-submit mt-1 ml-3"
                  v-on:click.prevent="showExtraOptions = ~showExtraOptions">
                  <span v-if="showExtraOptions">
                    📕 Скрыть ручной словарь
                  </span>
                  <span v-else>
                    📖 Показать ручной словарь
                  </span>
                </b-button>
              </div>
              <div v-if="showExtraOptions" class="mt-2 mb-0">
                <p>Это словарь для ручных замен.</p>
                <p>Если спеллчекер ошибочно исправлет какое-то слово, можно добавить это слов в словарь. </p>
                <p>Тогда оно либо не будет изменяться (запишите слово в поле "Исходное слово", поле "На что поменять"
                  оставьте пустым) </p>
                <p>Либо можно проставить то на что заменять это слово (запишите слово в поле "Исходное слово", а в поле
                  "На что поменять" запишите замену) </p>
                <div class="row">
                  <div class="col-2">
                    <div class="form-group">
                      <label> Исходное слово </label>
                      <input class="form-control" v-model="original" />
                    </div>
                  </div>
                  <!-- <div class="col-1" style="justify-content: center">
                    <p> ➡️ </p>
                  </div> -->
                  <div class="col-2">
                    <div class="form-group">
                      <label> На что поменять </label>
                      <input class="form-control" v-model="replacement" />
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <b-button class="prompt__btn prompt__btn-submit mt-4" @click="updateVocab()">💪 Добавить в
                        словарь</b-button>
                    </div>
                  </div>
                </div>
                <p>Cловарь для ручных замен:</p>
                <div class="row m-12 justify-content-left align-items-center">
                  <div class="col-4">
                    <div class="table-responsive bg-white shadow rounded">
                      <div style="max-height:20em; width: 100%; overflow-y:scroll; overflow-x:auto;">
                        <table class="table mb-0 table-center text-secondary"
                          style="font-size:small; width:100%; table-layout:fixed;" rules="groups">
                          <thead>
                            <tr>
                              <th scope="col" style="width:50%; text-align:left;">Исходное слово</th>
                              <th scope="col" style="width:50%; text-align:left;">На что поменять</th>
                            </tr>
                          </thead>
                          <tbody>
                            <template v-for="(m, m_id) in vocab">
                              <tr>
                                <td style="word-wrap: break-word">{{ m.original }}</td>
                                <td style="word-wrap: break-word">{{ m.replacement }}</td>
                              </tr>
                            </template>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <p>
        Нашли ошибку или что-то не работает? Сообщите @ermolaevpa (тг) или
        paermolaev@sberbank.ru
      </p>
    </div>
  </div>
</template>

<script>
import { height } from "dom7";
import store from "@/store/store";
import {
  SquareIcon,
  DownloadIcon,
  CopyIcon,
  RefreshCwIcon,
  HelpCircleIcon,
  WatchIcon,
  MessageCircleIcon,
  ThumbsUpIcon,
  RepeatIcon,
} from "vue-feather-icons";

export default {
  components: {
    SquareIcon,
    DownloadIcon,
    CopyIcon,
    RefreshCwIcon,
    HelpCircleIcon,
    MessageCircleIcon,
    ThumbsUpIcon,
    RepeatIcon,
  },

  name: "AI-Editor",

  data() {
    return {
      checkerQuery: {
        text: "",
      },
      true_hostname: this.$hostname_ba + "/spellchecker",
      //true_hostname: "http://0.0.0.0:8748",
      autoreplace: false,
      readyToCheckStyle: false,
      showExtraOptions: false,
      vocab: [],
      original: "",
      replacement: "",
    };
  },
  watch: {},
  async created() {
    console.log(this.$cookies.get("user"));
    this.hash = this.$CryptoJS.MD5("Hi There!" + new Date()).toString();
    console.log("hash = " + this.hash);

    document
      .getElementById("theme-opt")
      .setAttribute("href", "/css/style-null.css");
    document
      .getElementById("color-opt")
      .setAttribute("href", "/css/colors/default-null.css");

    this.getVocab();

  },
  // mounted() {
  //   this.getVocab();
  // },
  methods: {
    getVocab() {
      console.log("inside getVocab");

      this.axios({
        method: "post",
        url: this.true_hostname + "/get_vocab",
        data: {},
      })
        .then((res) => {
          this.vocab = res.data.result;
          console.log(this.vocab);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateVocab() {
      console.log("inside updateVocab");

      this.axios({
        method: "post",
        url: this.true_hostname + "/update_vocab",
        data: { "original": this.original, "replacement": this.replacement },
      })
        .then((res) => {
          console.log("updateVocab ok");
          this.getVocab();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    downloadInnerHtml() {
      let elHtml = document.getElementById("textEdit").innerText;
      let link = document.createElement("a");
      link.setAttribute("download", "result.doc");
      link.setAttribute(
        "href",
        "data:" + "text/doc" + ";charset=utf-8," + encodeURIComponent(elHtml)
      );
      link.click();
    },
    copyInnerHtml() {
      let elHtml = document.getElementById("textEdit").innerText;
      navigator.clipboard.writeText(elHtml);
    },
    clearInnerHtml() {
      document.getElementById("textEdit").innerText = "";
    },
    replaceText(i) {
      console.log("replaceText", i);
      this.raw_result[i].original_text = this.raw_result[i].candidate_text;
      this.raw_result[i].operation = "equal";
      this.rebuildTextForEdit();
    },
    rebuildTextForEdit() {
      this.punctuationErrorsCount = 0;
      let result = "";
      this.textForEdit = "";
      for (let i = 0; i < this.raw_result.length; i++) {
        if (this.raw_result[i]["operation"] !== "equal") {
          let background_color = "var(--color-success)";
          let kw = "Заменить на \"" + this.raw_result[i].candidate_text + `"`;

          if (this.raw_result[i]["operation"] === "insert") {
            background_color = "var(--color-success)";
            kw = "Вставить \"" + this.raw_result[i].candidate_text + `"`;
          }
          if (this.raw_result[i]["operation"] === "replace") {
            background_color = "var(--color-error)";
            kw = "Заменить на \"" + this.raw_result[i].candidate_text + `"`;
          }
          if (this.raw_result[i]["operation"] === "delete") {
            background_color = "var(--color-error)";
            kw = "Удалить";
          }

          result += `<span style="background-color: ${background_color}">
                    <div class="custom-tooltip" contenteditable="false">` +
            this.raw_result[i].original_text +
            `<span class="custom-tooltiptext custom-tooltip-top">
                          <a id="` +
            i +
            `"
                            class="btn btn-outline-primary btn-sm mr-2 mt-2 discard-replace-button" style="color: white; border-color: #7b56e2; background-color: #7b56e2;"
                            >` + kw +
            `</a>
                    </span>
                    </div>
                    </span>`;
          this.punctuationErrorsCount += 1;
        } else {
          result += this.raw_result[i].original_text;
        }
        this.textForEdit += this.raw_result[i].original_text;
      }
      document.getElementById("textEdit").innerHTML = result;

      let elements = document.getElementsByClassName("discard-replace-button");

      for (let i = 0; i < elements.length; i++) {
        console.log(elements[i].id);
        elements[i].addEventListener(
          "click",
          function () {
            this.replaceText(parseInt(elements[i].id));
          }.bind(this),
          false
        );
      }
      this.autoreplace = true;
      this.readyToCheckStyle = true;
    },
    autoReplace() {
      this.textForEdit = "";
      for (let i = 0; i < this.raw_result.length; i++) {
        this.textForEdit += this.raw_result[i].candidate_text;
      }

      document.getElementById("textEdit").innerHTML = this.textForEdit;
      this.autoreplace = false;
    },
    checkPunctuation() {
      console.log("inside checkPunctuation");
      this.$vs.loading({
        container: "#div-with-loading",
        scale: 1,
        opacity: 0.1,
      });

      this.checkerQuery.text = document.getElementById("textEdit").innerText;

      console.log({
        method: "post",
        url: this.true_hostname + "/process",
        data: this.checkerQuery,
      });

      this.axios({
        method: "post",
        url: this.true_hostname + "/process",
        data: this.checkerQuery,
      })
        .then((res) => {
          this.raw_result = res.data.result;
          this.rebuildTextForEdit();
          this.$vs.loading.close("#div-with-loading > .con-vs-loading");
        })
        .catch((error) => {
          this.$vs.loading.close("#div-with-loading > .con-vs-loading");
        });
    },
    checkStyle() {
      console.log("inside checkStyle");
      this.$vs.loading({
        container: "#div-with-loading",
        scale: 1,
        opacity: 0.1,
      });

      this.checkerQuery.text = document.getElementById("textEdit").innerText;

      console.log({
        method: "post",
        url: this.true_hostname + "/process_style",
        data: this.checkerQuery,
      });

      this.axios({
        method: "post",
        url: this.true_hostname + "/process_style",
        data: this.checkerQuery,
      })
        .then((res) => {
          this.raw_result = res.data.result;
          this.rebuildTextForEdit();
          this.$vs.loading.close("#div-with-loading > .con-vs-loading");
        })
        .catch((error) => {
          this.$vs.loading.close("#div-with-loading > .con-vs-loading");
        });
      this.readyToCheckStyle = false;
    },
    checkList() {
      console.log("inside checkLists");
      this.$vs.loading({
        container: "#div-with-loading",
        scale: 1,
        opacity: 0.1,
      });

      this.checkerQuery.text = document.getElementById("textEdit").innerText;

      console.log({
        method: "post",
        url: this.true_hostname + "/process_list",
        data: this.checkerQuery,
      });

      this.axios({
        method: "post",
        url: this.true_hostname + "/process_list",
        data: this.checkerQuery,
      })
        .then((res) => {
          this.raw_result = res.data.result;
          this.rebuildTextForEdit();
          this.$vs.loading.close("#div-with-loading > .con-vs-loading");
        })
        .catch((error) => {
          this.$vs.loading.close("#div-with-loading > .con-vs-loading");
        });
      this.readyToCheckStyle = false;
    },
  },
};
</script>

<style>
/* Offset between elements */
* {
  --container-width: 1366px;
  --offset-xxs: 4px;
  --offset-xs: 8px;
  --offset-sm: 12px;
  --offset-md: 16px;
  --offset-lg: 20px;
  --offset-xl: 24px;
  --offset-xxl: 28px;
  --offset-huge: 32px;
  --offset-jumbo: 40px;
  --color-light-gray: #97a0af;
  --color-light-bg-outlet: #e6e7f0;
  --color-light-bg-dark: #ebecf2;
  --color-light-black: #232628;
  --color-light-red: #ff8f73;
  --color-light-yellow: #ffe380;
  --color-light-divider-light: var(--color-text-light-gray);
  --color-light-bg-light: #f4f5f7;
  --color-light-bg-accent: #d3d8e7;
  --color-light-bg-accent-card: #ffffff51;
  --color-light-text-gray: #6b778c;
  --color-light-bg-gray: var(--color-light-bg-light);
  --size-xxs: 4px;
  --size-xs: 8px;
  --size-sm: 12px;
  --size-md: 16px;
  --size-lg: 20px;
  --size-xl: 24px;
  --size-xxl: 28px;
  --size-huge: 32px;
  --size-jumbo: 40px;
  --font-fallback: "Verdana", "Roboto", sans-serif;
  --font-base: "SB Sans Text", var(--font-fallback);
  --font-caps: "SB Sans UI Caps", var(--font-base);
  --font-12: 400 12px / 14px var(--font-base);
  --font-14: 400 14px / 18px var(--font-base);
  --font-14-medium: 500 14px / 18px var(--font-base);
  --font-14-semibold: 600 14px / 18px var(--font-base);
  --font-16: 400 16px / 20px var(--font-base);
  --font-16-caps: 400 16px / 20px var(--font-caps);
  --font-16-bold: 700 16px / 20px var(--font-base);
  --font-18: 400 18px / 23px var(--font-base);
  --font-18-medium: 600 18px / 23px var(--font-base);
  --font-20: 400 20px / 25px var(--font-base);
  --font-20-medium: 500 20px / 25px var(--font-base);
  --font-24-medium: 500 24px / 30px var(--font-base);
  --font-20-semibold: 600 20px / 25px var(--font-base);
  --font-28-medium: 500 28px / 35px var(--font-base);
  --font-28-bold: 600 28px / 35px var(--font-base);
  --font-32-semibold: 600 32px / 40px var(--font-base);
  /* Caps UI */
  --font-12-caps: 400 12px / 14px var(--font-caps);
  --font-20-caps: 400 20px / 28px var(--font-caps);
  --color-text-black: var(--color-light-black);
  --color-text-light-red: #ffd7de;
  --color-text-light-gray: #dfe1e6;
  --color-text-very-light-gray: #b1bac9;
  --color-text-very-dark-gray: #313845;
  --color-text-gray: var(--color-light-text-gray);
  --color-text-dark-white: #d9dde3;
  --color-text-light-yellow: #fff1ca;
  --color-primary: #7b56e2;
  --color-primary-dark: #7351d4;
  --color-interactive: #563599;
  --color-gray: #5c5c5c;
  --color-black: #2c2c2c;
  --color-gray-dark: #dadde3;
  --color-white: #fff;
  --color-red: #f00;
  --color-error: #f57a87;
  --color-success: #8dd9be;
  --color-danger: #ff5630;
}

.header {
  position: sticky;
  top: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: calc(var(--size-jumbo) + var(--size-xs));
  background-color: var(--color-black);
  padding: 0 var(--offset-jumbo);
}

.home {
  display: flex;
  align-items: center;
  height: 100%;
  padding: var(--offset-sm) 0;
  border: 0;
  border-radius: 0;
  font: var(--font-16-bold);
  color: var(--color-text-dark-white);
  text-decoration: none;
}

body {
  margin: 0;
}

.options {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.link {
  position: relative;
  padding: var(--offset-sm) var(--offset-xl);
  height: 100%;
  border: none;
  border-radius: 0;
  text-decoration: none;
  font: var(--font-14-medium);
  color: var(--color-text-dark-white);
}

.speakers {
  display: flex;
  gap: 8px;
  align-items: center;
}

.speakers svg {
  height: var(--size-xs);
  transform: rotate(90deg);
  transition: rotate 0.25s ease-in-out;
  width: var(--size-xs);
}

.link:has(+ a)::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 1px;
  height: calc(100% - var(--offset-sm) * 2);
  background-color: var(--color-light-gray);
}

:where(button, a)[aria-disabled="true"],
button:disabled,
:global(.ant-btn-link:disabled) {
  pointer-events: none;
  background-color: transparent;
  color: var(--color-gray);

  &:global(.ant-btn:hover) {
    background-color: transparent;
    color: var(--color-gray);
  }
}

div.search {
  padding: 0;
  color: var(--color-text-dark-white);
  width: var(--size-lg);
  height: var(--size-lg);
  border: 0;
}

.link {
  display: flex;
  text-decoration: none;
  color: #d9dde3;
}

.link:hover {
  color: #b1b5c5;
}

.textMedium {
  font: 500 14px / 18px "SB Sans Text", "Verdana", "Roboto", sans-serif;
}

.titleBold {
  font: 600 32px / 40px "SB Sans Text", "Verdana", "Roboto", sans-serif;
}

.mainspellchecker {
  min-width: 390px;
  padding-top: 32px;
  padding-left: 40px;
  padding-right: 40px;
}

.title {
  margin: 0;
  padding: 0;
}

ul {
  margin-left: 24px;
  margin-top: 12px;
  line-height: 1.5rem;
}

h5 {
  /* width: 1000px; */
  white-space: pre-wrap;
  line-height: 1.2em;
  font-size: 1.2em;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #000000;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #000000 transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Custom ui styles */

.prompt {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 32px;
}

.prompt__textarea {
  margin-bottom: 32px;
  resize: none;
  border-width: 2px;
  border-color: transparent;
  box-shadow: 0 0 0 1px #ebecf2;
  padding: 12px 18px !important;
}

.prompt__textarea:focus {
  border-color: #7b56e2;
  transition: 0.3s ease-in-out;
  box-shadow: 0 4px 0 rgba(42, 5, 130, 0.08);
}

.generation__textarea {
  margin-bottom: 32px;
  resize: none;
  border-width: 2px;
  border-color: transparent;
  box-shadow: 0 0 0 1px #ebecf2;
  padding: 12px 18px !important;
  border-radius: 0.25rem;
}

.generation__textarea:hover {
  border-color: #7b56e2;
}

.model__title {
  color: #495057;
}

.prompt__btn {
  font-weight: 500;
  margin: 0;
  outline: none;
  border: 0;
  cursor: pointer;
  padding: 11px 15px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #7b56e2;
  color: #fff;
  margin: 5px;
  border-radius: 4px;
  transition: 0.3s ease-in-out;
  box-shadow: 0 4px 0 rgba(42, 5, 130, 0.08);
}

.prompt__btn-stop {
  position: relative;
  padding-left: 28px;
  color: #232628;
  background-color: #f4f5f7;
  border: 1px solid #f4f5f7;
}

.prompt__btn-stop::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
  width: 12px;
  height: 12px;
  background-color: #232628;
  z-index: 1;
  opacity: 0.25;
  transition: 0.3s ease-in-out;
}

.prompt__btn-stop:active {
  color: #493a6a;
  border-color: #493a6a;
}

.prompt__btn-stop:hover,
.prompt__btn-stop:focus-visible {
  color: #7b56e2;
  border-color: #7b56e2;
}

.prompt__btn-stop:hover::before,
.prompt__btn-stop:focus-visible::before {
  background-color: #7b56e2;
  opacity: 0.9;
}

.prompt__btn-submit:active {
  background-color: #493a6a;
}

.flex-container {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
}

.flex-start {
  justify-content: flex-start;
}

.fb-chat {
  width: 100px;
  line-height: 60px;
  display: block;
  border-radius: 30% / 50%;
  position: fixed;
  left: 50%;
  /*top: 50%;
  transform: translate(-50%, -50%);*/
}

.fb-chat__bubbles {
  text-align: center;
}

.fb-chat__bubbles span {
  display: inline-block;
  background-color: #b6b5ba;
  width: 7px;
  height: 7px;
  border-radius: 100%;
  margin-right: 5px;
  animation: bob 2s infinite;
}

.fb-chat__bubbles span:nth-child(1) {
  animation-delay: -1s;
}

.fb-chat__bubbles span:nth-child(2) {
  animation-delay: -0.85s;
}

.fb-chat__bubbles span:nth-child(3) {
  animation-delay: -0.7s;
  margin-right: 0;
}

@keyframes bob {
  10% {
    transform: translateY(-10px);
    background-color: #9e9da2;
  }

  50% {
    transform: translateY(0);
    background-color: #b6b5ba;
  }
}

.feather-small {
  width: 18px;
  height: 18px;
}

.con-select {
  min-width: 250px;
}

[contentEditable="true"]:empty:not(:focus):before {
  content: attr(data-text);
}

/* .main-div {
  height: 300px;
  box-sizing: border-box;
  padding: 10px 10px 10px 10px;
  border: 1px solid #6c757d;
  border-radius: 6px;
  overflow-y: scroll;
}

.main-div-title {
  height: 50px;
  box-sizing: border-box;
  padding: 10px 10px 10px 10px;
  border: 1px solid #6c757d;
  border-radius: 6px;
  overflow-y: scroll;
} */

.main-div-edit {
  height: 500px;
  box-sizing: border-box;
  padding: 70px 70px 70px 70px;
  border: 1px solid #6c757d;
  border-radius: 6px;
  overflow-x: hidden;
  position: relative;
  resize: vertical;
}

.color-hover:hover {
  border-color: #7b56e2;
}

.custom-tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted rgb(251, 255, 0);
}

.custom-tooltip .custom-tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: white;
  color: black;
  text-align: center;
  padding: 0px 0;
  border-radius: 6px;

  position: absolute;
}

.custom-tooltip:hover .custom-tooltiptext {
  visibility: visible;
}

.custom-tooltip .custom-tooltiptext {
  width: 200px;
  bottom: 100%;
  left: 50%;
  margin-left: -100px;
  /* Use half of the width (200/2 = 100), to center the tooltip */
}

.custom-tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: white;
}

.custom-tooltip .custom-tooltiptext {
  opacity: 0;
  transition: opacity 0.5s;
}

.custom-tooltip:hover .custom-tooltiptext {
  opacity: 1;
}

.footer {
  /* position: fixed; */
  left: 0;
  bottom: 0;
  width: 100%;
  color: gray;
  text-align: center;
}
</style>
